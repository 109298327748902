







import Vue from 'vue'
export default class PageNotFound extends Vue {}
